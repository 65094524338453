.card {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  transition: box-shadow @transition-time;

  &:hover {
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
  }
}

.card__img-wrapper {
  width: 100%;
  height: 233px;
  border-bottom: 1px solid @border-color-new;
}

.card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__about {
  padding: 19px 24px 22px;
  max-height: 168px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card__name {
  margin: 0 0 9px;
  transition: color @transition-time;
  // max-height: 71px;
  overflow: hidden;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  .card:hover & {
    color: @accent-color;
  }
}

.card__additional {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: @date-color;
  margin: auto 0 0;

  p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
  }
}

.card__type {
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 10px;
  color: @accent-color;
}

.card__hint {
  text-align: right;
  text-transform: uppercase;
  color: @accent-color;
  font-weight: 500;
  opacity: 0;
  transition: opacity @transition-time;
  padding: 0;
  margin: 1em 0 0;

  .card:hover & {
    opacity: 1;
  }
}

.popup .card,  .popup .card:hover{
  box-shadow: none;
}
