.addable-menu {
  align-items: center;
  position: absolute;
  top: 0;
  & .new-search__toggle {
    border-color: transparent;
    margin-right: 14px;
  }

  & .new-search__toggle .new-search__toggle-icon {
    color: @slide-desc-color;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    height: 24px;
    width: 1px;
    background-color: @slide-desc-color;
    top: 50%;
    transform: translateY(-50%);
  }
}

.addable-menu__identificator {
  padding: 16px 12px 16px 16px;
  letter-spacing: 0.0025em;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: @accent-color;
  text-transform: uppercase;
}

.addable-menu__list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.addable-menu__link {
  padding: 18px 12px;
  letter-spacing: 0.0025em;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: @addable-menu-link;
  position: relative;
  &:hover {
    color: #FFE092;
  }
  &.active {
    color: @accent-color;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 24px);
      height: 4px;
      background-color: @accent-color;
    }
  }
}
