.content-tabs__text a {
  color: #b4891d;
  text-decoration: underline;

  &:hover {
    color: #cf9d30;
  }

  &:active {
    color: #b3882a;
  }
}

.content-tab {
	&__header {
		background: #FFFFFF;
		box-shadow: 0px 5px 10px 1px rgba(34, 60, 80, 0.2);
		border-radius: 4px;
	}
	&__menu {
		display: flex;
		padding-left: 0;
		background: #FFFFFF;
		border-radius: 4px;
		box-shadow: 0px 5px 10px 1px rgba(34, 60, 80, 0.2);
	}
	&__item {
		padding: 14px 26px;
		list-style-type: none;
		display: flex;
		align-items: center;
		&.content-tab__item--active {
			svg {
				fill: #DBAE63 !important;
			}
			span {
				color: #DBAE63;
			}
		}
	}
	&__link {
		display: flex;
		align-items: center;
	}
	&__link-icon {
		height: 24px;
		width: 24px;
		fill: #616161 !important;
	}
	&__link-text {
		margin-left: 8px;
		color: #616161;
		font-family: Roboto;
		font-weight: 500;
		font-size: 13px;
		line-height: 16px;
	}
	&__image {
		width: 100%;
		height: 352px;
		color: #FFFFFF;
		position: relative;
		background-color: #090909;
	}
	&__container {
		max-width: 744px;
		margin: 0 auto;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__title {
		font-size: 40px;
		line-height: 47px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		padding-bottom: 9px;
	}
	&__title-des {
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
	}
	&__table {
		max-width: 744px;
		margin: 0 auto;
		display: block;
	    flex-shrink: 1;
	    flex-grow: 1;
	    padding-top: 16px;
	    padding-bottom: 32px;
	   	flex-wrap: wrap;
	   	display: flex;
	}
	&__table-item {
		padding: 11px 0px;
		display: flex;
		width: 48%;
		border-bottom: 1px solid #a8a8a8;
		&:nth-child(even) {
			margin-left: 12px;
		}
		&:nth-child(odd) {
			margin-right: 12px;
		}
		&:last-child {
			border-bottom: none;
		}
		&:nth-child(7) {
			border-bottom: none;
		}
	}
	&__table-title {
		font-weight: normal;
		font-size: 14px;
		line-height: 100%;
		color: #A7A7A7;
		margin-bottom: 0;
		width: 30%;
		display: flex;
    	align-items: center;
	}
	&__table-description {
		padding-left: 12px;
		font-size: 14px;
		line-height: 100%;
		color: #616161;
		margin-bottom: 0;
		width: 70%;
		display: flex;
    	align-items: center;
	}
	&__desc-container {
		max-width: 744px;
		margin: 0 auto;
	}
	&__desc {
		margin-top: 24px;
		background-color: #ffffff;
		padding-top: 29px;
		padding-bottom: 32px;
		color: #616161;
		border-radius: 4px;
		box-shadow: 0px 5px 10px 1px rgba(34, 60, 80, 0.2);
	}
	&__desc-title {
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
	}
	&__text {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
	&__slider {
		display: none;
	}
}