.slider-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.slider-modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 900px;

}

.slider-modal__close-button {
  position: absolute;
  width: 25px;
  height: 25px;
  line-height: 1;
  color: white;
  text-align: center;
  cursor: pointer;
  right: 0;
  top: -20px;
  background-color: #242424;
  font-size: 20px;
}

.slider-modal__close-button:hover {}

.show-modal {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  z-index: 10;
}

.slider-modal__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  align-items: center;
}

.slider-modal__item {
  flex-shrink: 0;
}

.slider-modal__img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  max-height: 80vh;
  object-fit: contain;
}

.slider-modal .slider__pagination {
  bottom: -12px;
  right: auto;
  left: 50%;
  background-color: #242424;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  height: 36px;
  padding: 13px;
  max-width: 100%;
  text-align: center;
  width: auto;
  top: 100%;
  transform: translateX(-50%);
}
