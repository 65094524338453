.page-footer {
  padding: 55px 0 37px;
  background-color: @accent-bg;
  color: #fff;
}

.page-footer__content {

  @media (min-width: @tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-areas:
      "project project project"
      "navigations navigations contacts"
      "social social copyright";
  }
  @media (min-width: @desktop) {
    grid-template-columns: 3fr 1fr 3fr 3fr 2fr;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-areas:
      "project . navigations navigations contacts"
      "project . social copyright copyright";
  }
}

.page-footer__project {
  margin: 0 0 10px;

  @media (min-width: @tablet) {
    grid-area: project;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
  }
  @media (min-width: @desktop) {
    display: block;
  }
}

.page-footer__project-description {
  margin: 12px 0;
  font-size: 1.3rem;
  line-height: 1.8rem;

  @media (min-width: @tablet) {
    margin: 0 24px;
  }
  @media (min-width: @desktop) {
    margin: 38px 0 22px;
  }
}

.page-footer__navigations {
  grid-area: navigations;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
