.pagination {
  &--view-interview {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &--interview-bottom {
    padding: 20px 0 0;
  }
}

.pagination__item {
  .pagination--view-interview & {
    &:first-child,
    &:last-child {
      margin-right: 0;
      margin-left: 0;
      border: none;
      border-radius: 0;
      width: 32px;
      height: 32px;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.pagination__prev {
  .pagination--view-interview & {
    cursor: pointer;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #e8b859;

      svg {
        fill: #fff;
        opacity: 1;
      }
    }

    &:active {
      background-color: #7f5b00;

      svg {
        fill: #fff;
        opacity: 1;
      }
    }
  }
}

.pagination__next {
  .pagination--view-interview & {
    cursor: pointer;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #e8b859;

      svg {
        fill: #fff;
        opacity: 1;
      }
    }

    &:active {
      background-color: #7f5b00;

      svg {
        fill: #fff;
        opacity: 1;
      }
    }
  }
}
