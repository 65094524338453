// .b-info-cluster {
//     position: absolute;
//     left: 60px;
//     bottom: 100px;
//     background: #fff;
//     text-align: center;
//     border-radius: 8px;
//     /*padding: 10px;*/
    
// }
// .b-info-cluster.cluster-21,
// .b-info-cluster.cluster-22 {
//     position: absolute;
//     border-radius: 6px;
//     /*box-shadow: 0 0 10px rgba(0, 0, 0, .3);*/
//    /* padding: 5px;*/
//     background-color: transparent;

// }

// .media,
// .media-body {
//     zoom: 1;
//     overflow: hidden;
//     vertical-align: middle;
//     display: flex;
//     align-items: center;
// }

// .b-info-cluster.cluster-21 .media {
//     width: 20px !important;
//     border-radius: 0px 4px 4px 0px;
// }
// .b-info-cluster.cluster-21 .media-right {
//     border-radius: 4px;
//     transition: transform 0.3s ease 0s;
//     background-color: #ffffff;
//     color: #fff;
//     padding: 5px;
//     transform: translateX(-220%);
//     padding-left: 19px;
// }

// .b-info-cluster.cluster-21 .media-left {
//     position: relative;
//     z-index: 1;
//     padding-left: 5px;
//     padding-bottom: 5px;
//     padding-top: 5px;
//     transition: background-color 0.2s ease 0s;
// }
// .b-info-cluster.cluster-21 .media:hover {
//     width: calc(100% - 19px) !important;  
// }



// .b-info-cluster.cluster-21 .media:hover .media-left {
//     // background-color: #ffffff;
//     // border-radius: 4px 0px 0px 4px;
// }
// .b-info-cluster.cluster-21 .media:hover .media-right {
//    transform: translateX(-19px);
//    color: #333333;
// }

.b-info-cluster.cluster-21 {
    .media {
        &.active {
            width: calc(100% - 19px) !important;
            .media-right {
                transform: translateX(-19px;);
                color: #333333;
            }
        }
        &.middle-active {
            width: 100% !important;
            .media-right {
                background-color: transparent;
                transition: 0;
                transform: translateX(0);
                color: #333333;
                padding-left: 0;
            }
            &:hover {
                
                background-color: #fff;
                border-radius: 4px;
                h4 {
                   color: #333333 !important; 
                }
            }
        }
    }

}
.page-cemetry-map .section-map .b-info-cluster .media {
    flex-direction: row;
}
.leaflet-marker-icon.leaflet-interactive {
    z-index: 1 !important; 
    &:hover {
        z-index: 10 !important;
    }
}