.translate-section__original {
  font-size: 16px;
}

.translate-section__item {
  counter-increment: none;

  &:not(&--heading) {
    counter-increment: a;
  }

  &--no-counter {
    &::before {
      opacity: 0 !important;
    }
  }

  &--heading {
    color: gray;
    font-size: 14px;

    &::before {
      opacity: 0 !important;
    }

    span {
      text-align: center;
      display: block;
      width: 280px;
      font-style: italic;
    }
  }
}
