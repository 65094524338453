.banner {
  height: 305px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &--text {
    background-color: #363636;
    height: auto;
    min-height: 311px;
    border-bottom: 3px solid @accent-color;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    padding-bottom: 20px;
  }
}

.banner__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner__header {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  margin: 147px 0 8px;
  color: #fff;
}

.banner__description {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  max-width: 580px;
  color: #fff;

  & + & {
    margin-top: 1em;
  }
}
