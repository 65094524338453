.research-directions {
  grid-area: materials;
  margin: 10px 0 0;
  background: #FFFFFF;
  padding: 18px 24px 13px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  overflow: hidden;

  &--with-logo {
    padding-top: 0;
  }

  @media (min-width: @tablet) {
    margin-top: 0;
  }

  // @media (min-width: @desktop) {
    // height: 272px;

    // &--with-logo {
    //   height: auto;
    // }

    // &--special-project {
    //   height: auto;
    // }
  // }
}

.research-directions__image {
  padding: 26px 24px;
  border-bottom: 1px solid @border-color;
  width: calc(~"100% + 48px");
  margin: 0 -24px;
  background-color: @accent-bg;
}

.research-directions__header {
  background-color: #363636;
  font-size: 1.2em;
  font-weight: 800;
}

.research-directions__image-outer {
  height: 36px;
  width: 242px;

  & svg {
    height: 100%;
    width: 100%;
  }
}

.research-directions__header {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  padding: 20px 24px 19px;
  width: calc(~"100% + 48px");
  margin: 0 -24px 9px;
  background-color: @accent-bg;
  color: #fff;
}

.research-directions__list {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;

  & & {
    padding-left: 13px;
    margin: 0;

    .research-directions__link {
      font-weight: 400;
      padding-left: 8px;

      &::before {
        content: '-';
        width: auto;
        height: auto;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }
}

.research-directions__item {

}

.research-directions__link,
.research-directions__Sub-header {
  color: @slide-desc-color;
  font-size: 1.6rem;
  line-height: 1.6rem;;
  padding: 8px 0 8px 13px;
  display: block;
  position: relative;
  font-weight: bold;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    background-color: @accent-color;
    border-radius: 50%;
  }
}

.research-directions__Sub-header {
  margin-bottom: 0;
  text-transform: none;
}

.research-directions__item--addable ul {

}

.research-directions__item--addable ul li a {
  font-weight: normal;

  &::before {
    display: none;
  }
}

.research-directions__item--addable ul li:last-child {
  border-bottom: none;
}
