.map {
  padding: 60px 0 0;
}

.map .container {
  position: relative;

  @media (min-width: @desktop) {
    position: static;
  }
}

.map__header {
  margin: 0 0 34px;
}

.map__content {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  @media (min-width: @desktop) {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 360px 1fr;
    grid-column-gap: 0px;
    grid-template-areas:
      "search filters"
      "map map";
  }
}

.map__wrapper {
  height: 336px;
  grid-area: map;

  &:focus {
    outline: none;
  }

  .gm-style .gm-style-iw-c {
    padding: 0;
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }

  button.gm-ui-hover-effect {
    display: none !important;
  }

  * {
    &:focus {
      outline: none;
    }
  }

  @media (min-width: @tablet) {
    height: 384px;
  }

  @media (min-width: @desktop) {
    height: 624px;
  }
}
