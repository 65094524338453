.burger-menu-btns {
  height: 40px;
  width: 40px;

  & .unvisble {
    display: none;
  }

  & button {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;

    & svg {
      fill: @addable-menu-link;
    }

    &:hover svg {
      fill: @broadcrumbs-link;
    }

  }
}
