.name-card__title {
  font-size: 18px;

  div.name-card--lead &,
  .card .table div.name-card--lead & {
    font-size: 18px;
  }

  .epigraphic & {
    font-size: 16px;
  }

  .card .table & {
    font-size: 14px;
  }
}

.name-card__subtitle {
  &._xl {
    font-size: 16px;

    .name-card--lead &,
    .card .table .name-card--lead & {
      font-size: 18px;
    }
  }

  &._md {
    font-size: 16px;
  }

  .card .table & {
    font-size: 14px;
  }
}
