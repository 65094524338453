.favorites {
  flex-shrink: 0;
  display: flex;
  margin: 10px 0;

  @media (min-width: @tablet) {
    display: block;
  }
}

.favorites__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: @accent-color;
}

.favorites__icon {
  margin: 0 4px 0 0;
}
