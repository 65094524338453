.map-bubble {
}

.map-bubble__city {
  background-color: #FFE092;
  text-align: center;
  width: 100%;
  color: @accent-bg;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 8px;
}

.map-bubble__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.map-bubble__item {
  border-bottom: 1px solid @border-color;

  &:last-child {
    border-bottom: none;
  }
}

.map-bubble__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 6px 16px;

  @media (min-width: @tablet) {
    font-size: 14px;
    line-height: 24px;
  }
}

.map-bubble__link-icon {
  margin: 0 0 0 8px;
  color: @accent-color;
  width: 16px;
  height: 16px;

  @media (min-width: @tablet) {
    margin: 0 0 0 22px;
    width: 24px;
    height: 24px;
  }
}
