.public-stuff {
  padding: 21px 24px 26px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;

  @media (min-width: @desktop) {
    // height: 184px;
  }
}

.public-stuff__header {
  padding-bottom: 17px;
  font-size: 1.8rem;
  line-height: 2.1rem;
  border-bottom: 1px solid @border-color;
  margin-bottom: 14px;
  font-weight: bold;
}

.public-stuff__list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.public-stuff__link {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.015em;
  font-weight: 500;
  color: @slide-desc-color;
}

.stuff-link {

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    &::before {
      content: "";
      display: block;
      height:1px;
      width: 100%;
      border-bottom: 1px dashed #D8D8D8;
      position: absolute;
      bottom: 5px;
    }
    & span {
      z-index: 1;
      background-color: white;
    }
  }
  &__name {
    padding-right: 5px;
  }
  &__quantity {
    font-weight: 500;
    letter-spacing: 0.0225em;
    padding-left: 5px;
  }
}
