.media-body,
.media-left,
.media-right {
    display: table-cell;
    vertical-align: middle
}

.media-middle {
    vertical-align: middle
}

.media-bottom {
    vertical-align: bottom
}

.media-heading {
    margin-top: 0;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;

    br {
      content: "";
    }

    span {
        display: block;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        font-family: "Roboto";

    }

    .text-fade-50 {
      color: #616161 !important;
      font-weight: normal !important;
      padding: 5px 0px;
    }
}

.media-list {
    padding-left: 0;
    list-style: none
}

.media-date,
.media-info {
    color: rgba(97, 97, 97, .6);
    font-style: italic
}