.map-places {
  background: #ffffff;
  padding-right: 4px;
  @media (min-width: @desktop) {
    position: absolute;
    top: 56px;
    left: 0;
    width: 360px;
    height: calc(~"100% - 56px");
    z-index: 4;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transition: transform @transition-time;

    &--show {
      transform: translateX(0%);
    }
  }
}


.map-places__toggle {
  display: none;

  @media (min-width: @desktop) {
    border: none;
    display: block;
    width: 24px;
    height: 36px;
    border-radius: 0 4px 4px 0;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent @accent-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .map-places--show & {
      &::after {
        border-width: 6px 6px 6px 0;
        border-color: transparent @accent-color transparent transparent;
      }
    }

    &:focus {
      outline: none;
    }
  }
}

.map-places__list-wrapper {
  flex-grow: 1;
  position: relative;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
   
  &::-webkit-scrollbar-track {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
  }
   
  &::-webkit-scrollbar-thumb {
    background:  #E5E5E5;
    border-radius: 4px;
    outline: none;
  }
  
}


.map-places__list {
  width: 100%;
  height: 336px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;

  &::-webkit-scrollbar {
    width: 4px;
  }
   
  &::-webkit-scrollbar-track {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
  }
   
  &::-webkit-scrollbar-thumb {
    background:  #E5E5E5;
    border-radius: 4px;
    outline: none;
  }


  @media (min-width: @tablet) {
    height: 384px;
  }
  @media (min-width: @desktop) {
    height: 100%
  }
}

.map-places__item {
  width: 100%;
  border-top: 1px solid @border-color-new;

  &--empty {
    padding: 6px 24px;
  }
}

.map-places__button {
  display: block;
  width: 100%;
  border: none;
  background-color: #fff;
  text-align: left;
  padding: 6px 54px 6px 24px;
  position: relative;
  transition: background-color @transition-time;
  flex-shrink: 0;
  min-height: 55px;

  &--link {
    display: flex;
    align-items: center;
  }

  &:focus {
    outline: none;
  }

  &--back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: @accent-color;
    color: #fff;
    padding: 16px 24px;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;

    &:hover {
      background-color: @accent-color;
    }
  }
}

.map-places__name {
  display: block;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;

  .map-places__button:hover & {
    color: #DBAE63;
  }
}

.map-places__info {
  color: #606060;
  font-size: 1.2rem;
  line-height: 1.4rem;

  .map-places__button:hover & {
    color: #DBAE63;
  }
}

.map-places__button-icon {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);

  .map-places__button:hover & {
    fill: #DBAE63;
  }

  .map-places__button--back & {
    position: static;
    transform: rotate(-180deg);
    margin: 0 8px 0 0;
  }
}
