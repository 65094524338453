.partners {
  margin: 45px 0 0;
}

.partners__header {
  margin: 0 0 33px;
}

.partners-second-header {
  font-size: 18px;
  line-height: 21px;
  white-space: nowrap;
  max-width: 170px;
  margin: 0;
  margin-right: 32px;
  align-self: center;
}

.partners__wrappers {
  background-color: #fff;
  padding: 4px 0;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
}

.partners__wrapper {
  display: flex;
  padding: 20px 24px;

  & .slider__control {
    display: none;
  }

  & .slider {
    max-width: calc(100% - 200px);
  }
}

.partners__list, .sponsors__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  &.justify-start {
    justify-content: flex-start;
  }

  .partners--no-js & {
    flex-wrap: wrap;
    width: calc(~"100% + 64px");
    margin: 0 -32px;
  }
}

// .sponsors__list {
//   overflow: auto;
// }

.partners__item {
  flex-shrink: 0;
  // margin-right: 64px;

  &--noshrink {
    flex-shrink: 1;
  }
  .partners--no-js & {
    width: calc(~"25% - 64px");
    margin: 0 32px;
  }
  &:last-child {
    margin-right: 0;
  }

  @media (min-width: @tablet) {
    margin-right: 30px;
  }
}

.partners__link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  align-items: center;
}

.partners__img {
  width: auto;
  height: auto;
  max-width: 100%;
  filter: grayscale(100%);
  transition: filter @transition-time;

  .partners__link:hover & {
    filter: grayscale(0%);
  }
}
