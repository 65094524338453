.news-preview {
  border-right: 1px solid @border-color-new;
  background-color: #fff;
  max-height: 600px;
  overflow: hidden;
  padding-right: 4px;

  @media (min-width: @tablet) {
    width: 50%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (min-width: @desktop) {
    width: 380px;

    .news--page & {
      order: 2;
      max-height: 740px;
      margin-left: 24px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      overflow: hidden;
    }
  }
}

.news-preview__wrapper {
  overflow: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @border-color;
    border-radius: 4px;
  }
}

.news-preview__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.news-preview__item {
  border-bottom: 1px solid @border-color-new;
  flex-shrink: 0;
}

.news-preview__button {
 display: flex;
  height: 136px;
  flex-direction: column;
  width: 100%;
  padding-top: 19px;
  padding-bottom: 20px;
  padding-left: 24px;
  border: none;
  padding-right: 48px;
  background-color: transparent;
  text-align: left;
  position: relative;

  &:focus {
    outline: none;
  }

  &--active {
    position: relative;

    &::before {
      content: '';
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #FFE092;
    }
  }
}

.news-preview__name {
  display: block;
  // margin: 0 0 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  height: 63px;
  overflow: hidden;
  transition: 0.3s color;
  color: #363636;
  text-overflow: clip;

  .news-preview__button:hover & {
    color: @accent-color;
  }
}

.news-preview__additional {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: @date-color;
  position: static;
  flex-wrap: wrap;
  padding-top: 14px;

  .news--page & {
    flex-wrap: wrap;
  }
}

.news-preview__categories {
  list-style: none;
  padding: 0;
  margin: 0 0 0 12px;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  display: none;
}

.news-preview__category {
  padding: 0;
  margin: 0;
  position: relative;
  flex-shrink: 0;


  & + & {
    margin-left: 12px;
    padding-left: 12px;

    &::before {
      content: '';
      width: 1px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: 0;
      background-color: @border-color;
      transform: translateY(-50%);
    }
  }

  &:nth-child(n + 3) {
    display: none;
  }

  // &::after {
  //   content: '';
  //   width: 1px;
  //   height: 12px;
  //   position: absolute;
  //   top: 50%;
  //   right: 0;
  //   background-color: @border-color;
  //   transform: translateX(1px) translateY(-50%);
  // }

  // &:last-of-type {
  //   padding: 0;
  //   margin-right: 0;

  //   &::after {
  //     content: none;
  //   }
  // }
}

.news-preview__categories-hidden {
  // margin: 0 0 0 12px;
  // color: @accent-color;
  display: none;
}

.news-preview__all-link {
      width: 100%;
    display: block;
    text-align: center;
    flex-shrink: 0;
    letter-spacing: 0.0025em;
    color: #DBAE63;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 14px;
    padding-bottom: 18px;
    padding-top: 18px;
    border-top: 1px solid #F9F9F9;
}

.news-preview__date {
  display: block;
  color: @accent-color;
}

.news-preview__load-more {
  height: 1px;
  background-color: transparent;

  &--hidden {
    display: none;
  }
}

.news-preview__empty {
  text-align: center;
  padding: 15px;
  margin: 0;
  font-weight: bold;
}



.news--page {
  .news-preview__categories {
    display: flex;
  }
  .news-preview__categories-hidden {
    display: block;
  }
  .news-preview__category {
    display: none;
    &:first-child {
      display: block;
    }
  }
  .news-preview {
    border-right: 1px solid @border-color-new;
    background-color: #fff;
    max-height: auto;

  }
  .news-preview__wrapper {
    overflow: auto;
  }
}
