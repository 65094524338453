.map-filters {
  position: absolute;
  top: 0;
  right: 14px;

  @media (min-width: @tablet) {
    right: 28px;
  }

  @media (min-width: @desktop) {
    position: static;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    padding: 0;
    width: 100%;
    flex-shrink: 0;
    grid-area: filters;
  }
}

.map-filters__toggle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: @transition-time background-color;

  &--active {
    background-color: @accent-color;
  }

  &:focus {
    outline: none;
  }

  @media (min-width: @desktop) {
    display: none;
  }
}

.map-filters__toggle-icon {
  color: @accent-color;
  transition: @transition-time color;

  .map-filters__toggle--active & {
    color: #fff;
  }
}

.map-filters__list {
  padding: 0 0 0 24px;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: calc(~"100% + 8px");
  right: 0;
  z-index: 8;

  &--open {
    display: block;
  }

  @media (max-width: (@desktop - 1)) {
    background-color: #fff;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 16px 24px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    padding: 16px;
  }

  @media (min-width: @tablet) {
    top: calc(~"100% + 16px");
  }

  @media (min-width: @desktop) {
    position: static;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
  }
}

.map-filters__item {
  margin: 0 0 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: @desktop) {
    margin: 0 35px 0 0;
  }
}

.map-filters__name {
  width: 360px;
  flex-shrink: 0;
  position: relative;
  border-right: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
}

.map-filters__input {
  width: 100%;
  height: 55px;
  display: block;
  background-color: #f5f5f5;
  border: none;
  padding: 0 64px 0 24px;
}

.map-filters__label {
  text-transform: uppercase;
  display: block;
  margin: 0;
  padding: 2px 0;
  white-space: nowrap;

  &--locality {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }

  &--checkbox {
    padding: 0 0 0 30px;
    position: relative;
    cursor: pointer;
    line-height: 20px;
    transition: color @transition-time;
    font-weight: 500;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(./../images/icon_checkbox.svg);
      transition: background @transition-time;
    }

    input:checked ~ & {
      color: @accent-color;

      &::before {
      background-image: url(./../images/icon_checkbox--checked.svg);
      }
    }
  }
}

.map-filters__label-icon {
  display: block;
}
