.many-values-hiding {
  position: relative;
  max-height: 32px;
  width: 100%;
  height: 100%;
  // word-break: break-all;
  // -moz-hyphens: auto;
  //   -webkit-hyphens: auto;
  //   -ms-hyphens: auto;
}

.many-values-hiding.break-word {
  word-break: break-all;
}

.many-values-hiding__link {
  color: @broadcrumbs-link;
}

.many-values-hiding__link:hover {
  text-decoration: underline;
}

.many-values-hiding__link:hover + .many-values-hiding__all {
  opacity: 1;
  z-index: 10;
  background: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .1);
}


.many-values-hiding__all {
  position: absolute;
  opacity: 0;
  transition: all 0.2s;
  width: 200%;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
}

.many-values-hiding--geographic {
 width: 90px;
}

.many-values-hiding--keywords {
  width: 140px;
}

.popup .card-nav__link:hover .card-nav__name {
  color: #b3882a;
}

.popup .card-nav__link:hover svg {
  fill: #b3882a;
}


.popup .card__h3 ,.new-card h3 {
  font-weight: 400;
}

.time-location {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  margin-bottom: 20px;
}

.with-popup-title {
  position: relative;
  display: block;
}

.with-popup-title:hover .popup-title {
  opacity: 1;
  display: block;
  z-index: 10;
}

.popup-title {
  display: block;
  position: absolute;
  opacity: 0;
  transition: all 0.2s;
  width: 400%;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}
