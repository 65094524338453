.special-gallery {
  margin: 40px 0;
}

.special-gallery__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;

  .special-gallery--no-js & {
    display: block;
  }
}

.special-gallery__item {
  flex-shrink: 0;
  font-size: 12px;
  line-height: 16px;
  // height: 472px;
  height: 492px;
  display: flex;
  justify-content: center;

  .special-gallery--no-js & {
    margin-bottom: 20px;
  }

  // &--no-description {
  //   padding-bottom: 2em;
  // }

  img {
    height: 100%;
    align-self: center;
  }
}

.special-gallery__link {
  display: flex;
  justify-content: center;
  background-color: #F5F5F5;
}

.special-gallery__description {
  color: @slide-desc-color;
  padding: 12px 24px;
  width: 100%;
  background-color: rgba(255,255,255, 0.8);
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: @text-color;
  text-transform: none;

  &--detailed {
    margin: 0;
    font-style: italic;
  }
}

.special-gallery__description-italic {
  font-style: italic;
}

.special-gallery__item img {
  align-self: center;
  object-fit: cover;
  // width: 744px;
}

.special-gallery .swiper-pagination-fraction {
  position: relative;
  margin-top: 21px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  line-height: 16px;
  color: @slide-desc-color;
  width:75px;
  text-align: center;
  max-width: 75px;
}

.special-gallery .slider__control {
  bottom: 0;
  top: auto;
  box-shadow: none;
  width: 50px;
  background: transparent;
  bottom: -23px;
}

.special-gallery .slider__control.slider__control--disabled::before {
  border-bottom: 1px solid #D8D8D8;
}
.special-gallery .slider__control.slider__control--disabled::after {
  border-bottom: 1px solid #D8D8D8;
  border-right:1px solid #D8D8D8;
}

.special-gallery .slider__control::before {
  content: '';
  display: block;
  border-bottom: 1px solid @slide-desc-color;
  width: 100%;
}

.special-gallery .slider__control::after {
  content: '';
  display: block;
  height: 7px;
  width: 7px;
  position: absolute;

}

.special-gallery .slider__control--next::after {
  transform: rotate(-45deg);
  right: 0;
  border-bottom: 1px solid @slide-desc-color;
  border-right: 1px solid @slide-desc-color;
}

.special-gallery .slider__control--prev::after {
  transform: rotate(135deg);
  left: 0;
  border-bottom: 1px solid @slide-desc-color;
  border-right: 1px solid @slide-desc-color;
}

.special-gallery .slider__control--next {
  left: calc(50% - 37px);
  right:  auto;
}

.special-gallery .slider__control--prev {
  right: calc(50% - 37px);
  left:  auto;
}


.special-gallery .slider__control .slider__control-icon {
  display: none;
}

.special-card__image {
  width: 100%;
  height: auto;
}
