.other-sections {
  padding: 10px 0;

  @media (min-width: @tablet) {
    padding: 0;
    flex-shrink: 0;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.other-sections__toggle {
  display: none;
  background-color: transparent;
  border: none;
  color: @accent-color;
  padding: 10px;
  font-weight: bold;
  font-size: 1.4rem;
  // letter-spacing: 2px;
  padding: 15px 12px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }

  @media (min-width: @tablet) {
    display: block;
  }

  @media (min-width: @desktop) {
    padding: 30px 24px;
  }
}

.other-sections__list {
  padding: 0;
  margin: 0;
  list-style: none;

  @media (min-width: @tablet) {
    display: none;
    position: absolute;
    top: calc(~"100% + 8px");
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 4px;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 8px 7px;
      border-color: transparent transparent @accent-bg transparent;
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
    }

    .other-sections--open & {
      display: block;
    }
  }
}

.other-sections__item {
  background-color: @accent-bg;
  overflow: hidden;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.other-sections__link {
  display: block;
  color: @accent-color;
  white-space: nowrap;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 8px 6px;

  @media (min-width: @tablet) {
    text-align: center;
    padding: 7px 16px 8px;
  }
}

.other-sections__btn {
  display: none;
}
