.slider {
  position: relative;
  list-style: none;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.slider__wrapper {
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;

  .cards & {
    width: calc(~"100% + 20px");
    margin: 0 -10px;
    padding: 0 10px 15px;
  }
}

.slider__control {
  position: absolute;
  top: 50%;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:focus {
    outline: none;
  }

  // &:hover {
  //   background-color: #FFE092;
  // }

  // &:active {
  //   background-color: #A77F36;
  // }

  &--disabled {
    color: @date-color;
    cursor: default;

    &:hover,
    &:active {
      background-color: #fff;
    }
  }

  &.swiper-button-lock,
  &--hidden {
    display: none;
  }

  .promo & {
    background-color: transparent;
    border: 1px solid #fff;
    opacity: 0.7;

    &:hover {
      color: #FFE092;
      border-color: #FFE092;
    }

    &:active {
      color: #A77F36;
      border-color: #A77F36;
    }

    &--disabled {
      border-color: @border-color;
      color: @border-color;

      &:hover,
      &:active {
        border-color: @border-color;
        color: @border-color;
      }
    }
  }

  .promo &&--promo {
    background-color: rgba(0, 0, 0, 0.25);
    border: none;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      background-color: #363636;
      .slider__control-icon {
        fill: #FFE092;
      }
    }

    &:active {
      color: #A77F36;
      border-color: #A77F36;
    }

    &.slider__control--disabled {
      opacity: 0.3;

      &:hover,
      &:active {
        opacity: 0.3;
      }
    }
  }

  &--prev {
    left: -24px;
    transform: translate(-100%, -50%);

    .promo & {
      left: 0;
      top: calc(50% + 40px);
      transform: translate(100%, -50%);

      @media (min-width: @desktop) {
        left: calc(~"(100vw - 1310px) / 2");
        transform: translate(0%, -50%);
      }
    }
  }

  &--next {
    right: -24px;
    transform: translate(100%, -50%);

    .promo & {
      right: 0;
      top: calc(50% + 40px);
      transform: translate(-100%, -50%);

      @media (min-width: @desktop) {
        right: calc(~"(100vw - 1310px) / 2");
        transform: translate(0%, -50%);
      }
    }
  }
}

.slider__control-icon {
  display: block;
  fill:@accent-color;

  .slider__control:hover & {
    fill: @accent-color;
  }

  .slider__control:active & {
    fill: #A77F36;
  }

  .slider__control--disabled &,
  .slider__control--disabled:hover &,
  .slider__control--disabled:active & {
    fill: @date-color;
  }

  .slider__control--prev & {
    transform: rotate(-180deg) translateX(1px);
  }
}

.slider__pagination {
  position: absolute;
  right: 0;
  bottom: 0;
  left: auto;
  max-width: 40px;
  color: @date-color;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  background-color: #fff;
}

.slider__bullet-pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 17px;
  display: flex;

  & .swiper-pagination-bullet {
    height: 8px;
    width: 8px;
    border: 1px solid @accent-color;
    opacity: 1;
    margin: 0 4px;

    &.swiper-pagination-bullet-active {
      background-color: @accent-color;
    }
  }
}
