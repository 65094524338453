
.news-content {
  background-color: #fff;
  flex-grow: 1;

  .news--page & {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    overflow: hidden;
  }
}

.news-content__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.news-content__item {
  display: none;
  height: 100%;
  overflow: hidden;
  position: relative;

  &--active {
    display: block;
  }

  .news--page & {
    height: auto;
    overflow: auto;
  }
}

.news-content__content {
  padding: 26px 24px 48px;
  font-size: 1.6rem;
  line-height: 2.4rem;

  .news--page & {
    padding: 0 96px 30px;
  }

  p {
    margin: 12px 0 8px;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
  }

  ul {
    color: #616161;
  }

  &:hover {
    .news-content__link-wrapper {
      padding-top: 130px;
    }
    .news-content__link {
      transform: translateY(0);
    }
  }
}

.news-content__img {
  height: 408px;
  padding-right: 24px;
  padding-top: 24px;
  padding-left: 24px;

  &-no-padding {
    padding: 0 !important;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
  }
}

.news-content__name {
  margin: 0 0 1px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #363636;

  .news--page & {
    margin: 6px 96px 16px;
  }
}

.news-content__additional {
  display: none;
  justify-content: flex-start;
  align-items: flex-start;
  color: @date-color;
  margin-bottom: 20px;
  font-size: 1.4rem;
  line-height: 1.6rem;

  .news--page & {
    padding: 25px 96px 0;
  }
}

.news-content__date {
  color:@accent-color;
  margin: 0;
}

.news-content__categories {
  list-style: none;
  padding: 0;
  margin: 0 0 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
}

.news-content__category {
  padding: 0 12px 0 0;
  margin: 0 12px 0 0;
  position: relative;
  flex-shrink: 0;


  &::after {
    content: '';
    width: 1px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    background-color: @border-color;
    transform: translateY(-50%);
  }

  &:last-of-type {
    padding: 0;
    margin-right: 0;

    &::after {
      content: none;
    }
  }
}

.news-content__link-wrapper {
  cursor: pointer;
  transition: padding .3s ease 0s;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 24px 0px 18px;
  margin: 0px 24px;
  background: linear-gradient(360deg, #FFFFFF 15.28%, rgba(255, 255, 255, 0) 100%);
}

.news-content__link {
  margin-bottom: 0 !important;
  transition: transform .3s ease 0s;
  transform: translateY(200%);
  color: @accent-color !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-right: 48px;

  svg {
    margin: 0 0 0 4px;
  }
}

.news-content__btns {
  position: absolute;
  bottom: 20px;
  right: 0;
  padding: 0 24px;
  text-align: right;
  z-index: 1;

  &.unvisible {
    display: none;
  }
}

.news-content__btn {
  border: none;
  background: none;
  color:@accent-color;
  text-transform: uppercase;
  letter-spacing: 0.0025em;
  font-weight: 500;
  &.unvisible {
    display: none;
  }
}


.news--page {
  & .news-content__additional {
    display: flex;
  }
  .news-content__category {
    display: none;
    padding-right: 0;
    margin-right: 0;
    &:first-child {
      display: block;
    }
    &:after {
      content: none;
    }
  }
  .news-content__category-full {
    display: block !important;
    padding-right: 12px !important;
    margin-right: 12px !important;
    &:after {
      content: "" !important;
    }
    &:last-child {
      &:after {
        content: none !important;
      }
    }
  }
}
