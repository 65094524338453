.new-search {
  margin-left: auto;
}

.new-search__toggle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid @slide-desc-color;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: @slide-desc-color;
  transition-property: color, background-color;
  transition-duration: @transition-time;
  cursor: default;

  .new-search--open & {
    background-color: @accent-color;
    color: @text-color;
  }

  &:focus {
    outline: none;
  }
}

.new-search__form {
  display: none;
  width: 290px;
  position: absolute;
  top: calc(~"100% + 24px");
  left: 50%;
  transform: translateX(-50%);

  .new-search--open & {
    display: block;
  }

  @media (min-width: @tablet) {
    width: 552px;
  }
}

.new-search__input {
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 64px 0 24px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 16px 24px rgba(0, 0, 0, 0.14);
  border-radius: 36px;
  border: 1px solid #FFE092;
  background-color: #fff;

  &:focus {
    outline: none;
  }
}

.new-search__submit {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: @accent-color;
}
