.sefer {
  flex-shrink: 0;
  padding-top: 12px;
}

.sefer__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.sefer__img {
  display: block;
  margin: 0 10px 0 0;
}
