.btn {
	&--catalog {
		white-space: nowrap;
	    padding: 8px;
	    border: 1px solid #b3882a;
	    font-size: 14px;
	    font-weight: 500;
	    line-height: 18px;
	    border-radius: 2px;
	    display: flex;
	    align-items: center;

	    &:active,
	    &:hover {
	    	color: #fff;
    		background-color: #cf9d30
	    }

	    &.icon:not(last-child) {
	    	margin-right: .45em
	    }

	    svg {
	    	fill: currentColor !important;
		    height: 20px;
				width: 20px;
	    }

	    span {
	    	margin-left: 10px;
	    }
	}
}
