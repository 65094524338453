*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 1.43;
  font-family: Roboto, sans-serif;
  color: @text-color;

  &.no-scroll {
    overflow: hidden;
  }
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
  font: inherit;

  &:not([class]) {
    color: @accent-color;

    &:hover {
      color: @accent-color-dark;
    }
  }
}

input {
  font: inherit;
}

button {
  font: inherit;
}

h2,
.h2 {
  font-size: 2.2rem;
  line-height: 2.6rem;
  font-weight: bold;
  color: inherit;

  @media (min-width: @desktop) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

h3,
.h3 {
  font-size: 2rem;
  font-weight: 500;
  color: inherit;
}

h4,
.h4 {
  font-size: 1.6rem;
  font-weight: 500;
  color: inherit;
}

main {
  background-color: #ffffff;
}