.main-navigation {

  @media (min-width: @desktop) {
    width: auto;
  }
}

.main-navigation__list {
  padding: 10px 0;
  margin: 0;
  list-style: none;

  @media (min-width: @tablet) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
  }
  @media (min-width: @desktop) {
    padding: 0 24px;
  }

  &--dropdown {
    padding: 0;

    @media (min-width: @tablet) {
      padding: 10px 0;
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: @accent-bg;
      box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
      border-radius: 0px 0px 4px 4px;
      overflow: hidden;
      min-width: 100%;
      z-index: 1;

      .main-navigation__button:hover ~ &,
      .main-navigation__item:focus-within &,
      &:hover {
        display: block;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
      }
    }

    @media (min-width: @desktop) {
      padding: 0;
    }
  }
}

.main-navigation__item {
  margin: 0 1px;
  position: relative;

  .main-navigation__list--dropdown & .main-navigation__link:hover {
    &::after {
      content: none;
    }
  }

  .main-navigation__list--dropdown &:not(:last-of-type) {
    &::before {
      content: '';
      display: block;
      width: 128px;
      height: 1px;
      background-color: #444444;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .page-header--addable-menu-open.page-header--main-menu-open &:hover::after {
    content: none;
  }

  .page-header--addable-menu-open.page-header--main-menu-open &:hover {
    background-color: #282828;
    & > ul {
      background-color: #282828;
    }
  }
}

.main-navigation__link {
  display: block;
  color: @accent-color;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 6px;
  position: relative;
  transition: color @transition-time;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 48px);
    height: 4px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    transition: background-color @transition-time;
  }

  &:hover {
    color: #FFE092;

    &::after {
      background-color: #FFE092;
    }

    .page-header--addable-menu-open.page-header--main-menu-open &::after {
      content: none;
    }
  }

  span {
    text-transform: lowercase;
    color: @date-color;
    display: none;

    .page-header--addable-menu-open & {
      display: inline;
    }
  }

  @media (min-width: @tablet) {
    padding: 15px 12px;

    .main-navigation__list--dropdown & {
      padding: 14px;
      font-size: 1.2rem;
      letter-spacing: 0.0025em;
      text-align: center;
      white-space: nowrap;
      font-weight: normal;
      color: white;

      &--special {
        white-space: inherit;
      }

      &:hover {
        color: #FFE092;
      }
    }
  }
  @media (min-width: @desktop) {
    padding: 30px 24px;
  }
}

.main-navigation__link[href*="city"] {
  color: @slide-desc-color;
  transition: color 0s;
  pointer-events: none;
}

.main-navigation__button {
  border: none;
  background-color: transparent;
  display: none;
  color: @accent-color;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 6px;
  position: relative;
  transition: color @transition-time;
  letter-spacing: 0.0025em;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 48px);
    height: 4px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    transition: background-color @transition-time;
  }

  .main-navigation__item:hover & {
    color: #FFE092;

    &::after {
      background-color: #FFE092;
    }
  }

  &:focus {
    outline: none;
  }

  .page-header--addable-menu-open .main-navigation__item:hover &::after {
    content: none;
  }

  @media (min-width: @tablet) {
    padding: 15px 12px;
    display: block;
  }
  @media (min-width: @desktop) {
    padding: 30px 24px;
  }
}

.main-navigation__item:first-of-type .main-navigation__item:first-of-type .main-navigation__link {
  color: #606060;
  transition: color 0s;
  pointer-events: none;
}
