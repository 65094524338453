.support-project {
  margin: 10px 0;

  @media (min-width: @tablet) {
    margin: 40px 0 48px;
  }
}

.support-project__wrapper {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;

  @media (min-width: @tablet) {
    padding: 22px 40px;
  }

  @media (min-width: @desktop) {
    padding: 38px 210px 32px;
  }
}

.support-project__header {
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: bold;
  padding: 0;
  margin: 0 0 17px;
}

.support-project__description {
  padding: 0;
  margin: 17px 0 16px;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.support-project__contacts {
  padding: 0 27px;
  position: relative;

  &::before {
    content: '';
    width: 3px;
    height: 100%;
    background-color: @accent-color;
    position: absolute;
    top: 0;
    left: -2px;
  }

  @media (min-width: @desktop) {
    padding: 0 70px 2px 26px;
  }
}

.support-project__contacts-header {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  padding: 0;
  margin: 0 0 7px;
}

.support-project__contacts-list {
  list-style: none;
  padding: 0;
  margin: 7px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.support-project__contacts-item {
  margin: 0 25px 0 0;
}

.support-project__contacts-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: @slide-desc-color;

  &:hover {
    color: #cf9d30;
  }

  &:active {
    color: #b3882a;
  }

  svg {
    color: @accent-color;
    margin: 0 8px 0 0;
  }
}
