.quote {
  position: relative;
  padding-left: 27px;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 3px;
    height: 100%;
    left: 0;
    background-color: @accent-color;
  }
}

.quote__text {
  color: @accent-bg;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
