.map-search {
  height: 48px;
  flex-shrink: 0;
  position: relative;
  grid-area: search;

  @media (min-width: @desktop) {
    height: 56px;
  }
}

.map-search__input {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 16px;
  border: none;

  &:focus {
    outline: none;
  }

  @media (min-width: @desktop) {
    border-right: 1px solid @border-color-new;
    padding: 20px 24px;
  }
}

.map-search__icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: #dadada;
}
