.promo {
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  color: #fff;
  background-color: @accent-bg;

  &::after {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, #A77F36 35.42%, #FFE092 100%);
    z-index: 1;
  }
}

.promo__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  .promo--no-js & {
    display: block;
  }
}

.promo__item {
  padding: 90px 0 30px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(30, 30, 30, 0.6), rgba(30, 30, 30, 0.6));
  }

  &--gorskiy {
    background-position-y: 30%;

    &::before {
      content: none;
    }
  }

  .promo--no-js & {
    &:nth-child(n + 2) {
      display: none;
    }
  }

  @media (min-width: @tablet) {
    padding: 200px 0 100px;

    .promo--small & {
      padding: 141px 0 53px;
    }
  }

  @media (min-width: @desktop) {
    padding: 175px 0 95px;

    .promo--small &--gorskiy,
    &--gorskiy {
      padding: 141px 0 53px;
    }
  }
}

.promo__content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 5fr;
  grid-column-gap: 10px;
  grid-row-gap: 7px;
  grid-template-areas:
    "header header header"
    "description description description"
    "link link .";

  .promo--small & {
    grid-template-areas:
      "header header header"
      "description description description"
  }

  @media (min-width: @desktop) {
    grid-template-columns: 1fr 6fr 5fr;
    grid-column-gap: 3px;
    grid-row-gap: 7px;
    grid-template-areas:
      "header header ."
      "description description ."
      "link link .";
    .promo--small & {
      grid-template-areas:
        "header header ."
        "description description .";
    }
  }
}

.promo--small .promo__content--big {
  @media (min-width: @desktop) {
    grid-template-areas:
		"header header header"
		"description description description"
		"date link .";
  }
}

.promo__header {
  margin: 0 0 18px;
  grid-area: header;
  font-weight: bold;

  // .promo__item--gorskiy & {
  //   margin: 0 0 9px;
  // }

  span {
    font-weight: 300;
  }
}

.promo__descriptions {
  grid-area: description;
  font-size: 1.6rem;
  line-height: 2.4rem;

  p {
    margin: 0 0 8px;
    grid-area: description;

    &:last-child {
      margin-bottom: 12px;
    }

    .promo__item--gorskiy & {
      font-size: 1.5rem;
    }
  }
}

.promo__date {
  grid-area: date;
}

.promo__link-wrapper {
  grid-area: link;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
}

.promo__link {
  color: @accent-color;
  padding: 6px 14px;
  border: 1px solid #DBAE63;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    border-color: #FFE092;
    color: #FFE092;
  }
}
