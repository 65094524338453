.special {
  padding: 16px 0;

  @media (min-width: @tablet) {
    padding: 0;
  }

  @media (min-width: @desktop) {
    margin: 64px 0 0;
  }
}

.special__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.special__item {
  padding: 0;
  margin: 0 0 10px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  overflow: hidden;

  @media (min-width: @tablet) {
    margin: 0 0 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}


.page__table-wrapper {
  margin-bottom: 100px;
}

.special-image-text {
  font-style: italic;
  font-size: 1.4rem;
    line-height: 2.0rem;
    margin: 0.5em 0;
    text-align: center;

}

.special__link {
  color: #DBAE63;
  text-decoration: none;
  transition: 0.2s color;
}

.special__link:hover {
  color: #cf9d30;
}

.special__quote {
  margin-top: 17px;
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
  }
  li:first-child {
    margin-right: 24px;
  }
  .special__quote-link {
    color:#606060;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  svg {
    margin-right: 8px;
  }
}
