.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
  padding: 0;
  background-color: #f9f9f9;
}

.page-footer {
  flex: 0 0 auto;
}

.container {
  margin: 0 auto;
  width: 320px;
  padding: 0 14px;

  @media (min-width: @tablet) {
    width: @tablet;
    padding: 0 28px;
  }
  @media (min-width: @desktop) {
    width: @desktop;
    padding: 0 56px;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.site-login,
.site-error {
  padding: 50px 0;

  @media (min-width: @tablet) {
    padding: 180px 0 90px;
  }
}
