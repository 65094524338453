.special-table{
  width: 100%;
  border: 1px solid #DBAE63;
  border-collapse: collapse;
  text-align: center;
}
.special-tr--head{
	background: #DBAE63;
}
.special-tr{
	&:nth-of-type(even){
		background: #F6EAD6;
	}
}
.special-td{
	padding: 5px;
	border: 1px solid #DBAE63;
	text-align:center;
	&:first-of-type{
		text-align: left;
	}
}
