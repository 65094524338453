.map-type-toggle {
  position: absolute;
  top: 72px;
  right: 16px;
  z-index: 1;
  @media (max-width: @desktop) {
    display: none;
  }
}

.map-type-toggle__list {
  display: flex;
  padding-left: 0;
  list-style: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
}


.map-type-toggle__button {
  border: none;
  background-color: white;
  box-shadow: none;
  height: 25px;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #757575;
  width: 68px;
  outline: none;
  li:first-of-type & {
    border-radius: 4px 0px 0px 4px;
  }
  li:last-of-type & {
    border-radius: 0px 4px 4px 0px;
  }
  &:hover {
  color: @accent-color;
  }
}

.map-type-toggle__button--active {
  font-weight: bold;
  color: white;
  background-color: @accent-color;
  &:hover {
    color: white;
  }
}
