.page-content {
  display: block;
  background: #f9f9f9;

  @media (min-width: @tablet) {
    margin: -110px 0 0;
    padding-bottom: 60px;
  }

  @media (min-width: @desktop) {
    margin: -80px 0 0;
    padding-bottom: 100px;
  }
}

.page__content-pagination-audio {
  .page__content-player & {
    @media (min-width: @desktop) {
      margin-left: 20px;
    }
  }
}
