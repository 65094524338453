.news {
  padding: 0;

  &--page {
    padding: 0;
    // margin: 0 0 100px;
  }
}

.news__header {
  margin: 0 0 33px;
}

.news__row {
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  .news--page & {
    border-radius: 0;
    box-shadow: none;
  }

  @media (min-width: @tablet) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  @media (min-width: @desktop) {
    .news--page & {
      align-items: flex-start;
    }
  }
}
