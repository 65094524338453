.page-header {
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
  z-index: 5;
  &__navigation {
    display: flex;
    align-items: center;
  }
  &__addable-menu {
    background-color: @accent-bg;
    display: none;
  }
}

.page-header__row {

  .container {
    @media (min-width: @tablet) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &--top {
    background-color: @accent-bg;
    padding: 13px 0 11px;
    border-bottom: 1px solid @accent-color;
  }

  &--bottom {
    background-color: @accent-bg;
    padding: 12px 0;
  }

  @media (min-width: @tablet) {
    &--bottom {
      background-color: rgba(16, 16, 16, 0.5);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
      &:hover {
        background-color:#363636
      }
    }
  }
  @media (min-width: @desktop) {
    padding: 0;
  }
}

.page-header__addable-menu {
  margin-left: 244px;
  width: calc(100% - 244px);
}

.page-header__burger-menu-buttons {
  display: none;
  left:16px;
  top: 6px;
}

.page-header__progress-bar {
  display: none;
}


.page-header--addable-menu-open {
  position: fixed;
  width: 100%;
  z-index: 9999;

  & .page-header__addable-menu {
    display: flex;
  }

  & .page-header__row--top {
    position: absolute;
    bottom: 100%;
  }

  & .page-header__row--bottom {
    background-color: #363636;
  }

  & .container {
    width: 100%;
    max-width: 1440px;
    padding: 0;
    display: block;
    position: relative;
  }

  & .logo {
    width: 244px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 14px;
    position: relative;
    z-index: 2;
    background-color: @text-color;
    & img{
      width: 160px;
      margin-left: auto;
    }
  }

  & .main-navigation__list {
    display: block;
    padding: 0;
  }

  & .page-header__row--bottom .new-search {
    display: none;
  }

  & .page-header__row--bottom .addable-menu .new-search {
    display: block;
  }

  & .main-navigation, & .other-sections {
    width: 244px;
  }

  & .page-header__burger-menu-buttons {
    display: block;
    position: absolute;
  }

  & .page-header__navigation {
    position: absolute;
    margin-top: -50%;
    z-index: -1;
  }

  & .page-header__burger-menu-buttons {
    display: block;
    z-index: 3;
  }

  & .other-sections__toggle {
    display: none;
  }

  .page-header__progress-bar {
    display: block;
    position: relative;
  }

  &.page-header--main-menu-open {

    & .page-header__navigation {
      display: block;
      margin-top: 0;
      background-color: @text-color;
      transition: margin-top 0.4s;
      border-top: 4px solid @accent-color;
    }

    & .main-navigation__list--dropdown {
      position: relative;
    }

    & .main-navigation__item .main-navigation__item:not(:last-child), & .main-navigation__button {
      &::before {
        content: '';
        display: block;
        height: 1px;
        width: 196px;
        background-color: #464646;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }
    }

    & .main-navigation > .main-navigation__list > .main-navigation__item:last-of-type {
      border-top: 1px solid #555;
    }

    & .main-navigation__link, & .main-navigation__button {
      padding: 14px 24px;
    }

    & .main-navigation__button {
      pointer-events: none;
      position: relative;
      width: 100%;
      text-align: left;
    }

    & .main-navigation__list--dropdown, & .main-navigation {
      box-shadow: none;
      border-bottom: 1px solid #555;
      border-radius: 0;
      position: relative !important;
    }

    & .main-navigation__list--dropdown .main-navigation__link, & .other-sections__link {
      font-weight: normal;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: left;
      position: relative;
      white-space: normal;
      //&::before {
      //  content: "";
      //  display: block;
      //  height: 6px;
      //  width: 6px;
      //  border-top: 1px solid @accent-color;
      //  border-right: 1px solid @accent-color;
      //  position: absolute;
      //  left: 24px;
      //  top: 50%;
      //  transform: translateY(-50%) rotate(45deg);
      //}
      &:hover {
        &::before {
          border-top: 1px solid #FFE092;
          border-right: 1px solid #FFE092;
        }
      }
    }

    & .other-sections {
      display: block;
    }

    .other-sections__list::before {
      display: none;
    }

    & .other-sections__list {
      display: block;
      position: relative;
    }

    & .other-sections__btn {
      display: block;
    }
  }
}


