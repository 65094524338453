.news-filter {
  background-color: @accent-bg;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  position: relative;
  flex-shrink: 0;
  width: calc(100% + 4px);
}

.news-filter__name {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin: 0;
  padding: 0;

  span {
    color: @accent-color;
  }
}

.news-filter__toggler {
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;

  &:hover,
  .news-filter--open & {
    color: @accent-color;
  }

  svg {
    display: block;
  }

  &--asc {
    svg {
      transform: rotateX(180deg);
    }
  }
}

.news-filter__list {
  list-style: none;
  padding: 0 24px;
  margin: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  display: none;

  .news-filter--open & {
    display: block;
  }
}

.news-filter__item {
  border-bottom: 1px solid @border-color;

  &:last-child {
    border-bottom: none;
  }
}

.news-filter__button {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 16px 0;
  cursor: pointer;
  color: @text-color;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s color;

  &:hover {
    color: @accent-color;
  }

  svg {
    display: block;
    margin: 0 0 0 5px;
  }

  &--ascending {
    svg {
      transform: rotateX(180deg);
    }
  }
}
