.news-categories {
  padding: 0 0 20px;
}

.news-categories__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.news-content__category {
  display: none;
  
  &:first-child {
    display: flex;
  }
}
.news-categories__item {
  margin: 0 12px 12px 0;
}

.news-categories__button {
  border: none;
  display: block;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  padding: 6px 16px;
  transition: 0.2s color, 0.2s background-color;
  background-color: #fff;

  &:hover:not(&--active) {
    color: #fff;
    background-color: @accent-color;
  }

  &:focus {
    outline: none;
  }

  &--active {
    color: @accent-color;
    background-color: #fff;
    cursor: default;
  }
}
